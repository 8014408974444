import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Button, Typography } from "@material-ui/core";
import useStyles from "../hooks/Styles";
import useAuthentication from "../hooks/useAuthentication";
import {
  getDataFromCustomRoute,
  postDataToCustomRoute,
} from "../providers/DataProvider";
import {
  fetchQueueStatsUrl,
  fetchTotalListingsValueUrl,
  triggerBoxOfficeDeployUrl,
  triggerEmptyQueueUrl,
  triggerExistingListingsSyncUrl,
  triggerTevoFeedSyncUrl,
  triggerSearchIndexRebuildUrl,
  boxOfficeDeploymentsUrl,
} from "../../utils/urls";

const DashboardPage = () => {
  const { user, authToken } = useAuthentication();
  const styles = useStyles();

  if (!authToken) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <TotalListings user={user} authToken={authToken} styles={styles} />
      <QueueState user={user} authToken={authToken} styles={styles} />
      <BoxOffice user={user} authToken={authToken} styles={styles} />
    </div>
  );
};

// @ts-ignore
const TotalListings = ({ user, authToken, styles }) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const [totalListingValue, setTotalListingValue] = useState<null | number>(
    null
  );
  useEffect(() => {
    (async () => {
      const url = fetchTotalListingsValueUrl();
      const value = await getDataFromCustomRoute(url, authToken, user);
      setTotalListingValue(value);
    })();
  }, []);

  if (!totalListingValue) return null;

  return (
    <Card className={styles.marginBottom} variant="outlined">
      <CardContent>
        <Typography variant="h4" className={styles.marginBottom}>
          Metrics
        </Typography>
        <Typography variant="h6">
          Total Available Listings: {formatter.format(totalListingValue)}
        </Typography>
      </CardContent>
    </Card>
  );
};

// @ts-ignore
const QueueState = ({ user, authToken, styles }) => {
  const [queueState, setQueueState] = useState(null);

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
  const clearQueue = () => {
    (async () => {
      const url = triggerEmptyQueueUrl();
      await getDataFromCustomRoute(url, authToken, user);
      await delay(400).then(() => updateQueue());
      alert("Empty Queue Initiated");
    })();
  };
  const updateQueue = () => {
    (async () => {
      try {
        const url = fetchQueueStatsUrl();
        const value = await getDataFromCustomRoute(url, authToken, user);
        setQueueState(value);
      } catch (e) {
        console.log(e);
      }
    })();
  };
  const scheduleAllTevo = () => {
    (async () => {
      const url = triggerTevoFeedSyncUrl();
      await getDataFromCustomRoute(url, authToken, user);
      await delay(400).then(() => updateQueue());
      alert("Entire tevo sync initiated");
    })();
  };
  const scheduleExistingListingSync = () => {
    (async () => {
      const url = triggerExistingListingsSyncUrl();
      await getDataFromCustomRoute(url, authToken, user);
      await delay(400).then(() => updateQueue());
      alert("Existing listings sync initiated");
    })();
  };

  // Populate on load
  useEffect(() => {
    updateQueue();
  }, []);

  if (!queueState) return null;

  return (
    <Card className={styles.marginBottom} variant="outlined">
      <CardContent>
        <Typography variant="h4" className={styles.marginBottom}>
          Inventory Operations
        </Typography>
        <div className={styles.marginBottom}>
          <Typography variant="h6">Tevo Queue State</Typography>
          <pre className={styles.marginBottom}>
            <code>{JSON.stringify(queueState, null, 2)}</code>
          </pre>
        </div>

        <div className={styles.marginBottom}>
          <Typography variant="h6">Tevo Queue Actions</Typography>
          <Button
            variant="contained"
            color="primary"
            className={styles.marginRight}
            onClick={updateQueue}
          >
            Refresh queue state
          </Button>
          <Button variant="contained" color="primary" onClick={clearQueue}>
            Empty Queue
          </Button>
        </div>
        <div className={styles.marginBottom}>
          <Typography variant="h6">Tevo Job Triggers</Typography>
          <Button
            variant="contained"
            color="primary"
            className={styles.marginRight}
            onClick={scheduleAllTevo}
          >
            Full Tevo Sync
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={scheduleExistingListingSync}
          >
            Existing Listings Sync
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

// @ts-ignore
const BoxOffice = ({ user, authToken, styles }) => {
  const rebuildBoxOffice = () => {
    (async () => {
      const url = triggerBoxOfficeDeployUrl();
      await getDataFromCustomRoute(url, authToken, user);
      alert("Box Office rebuild started");
    })();
  };

  const rebuildSearchIndex = () => {
    (async () => {
      const url = triggerSearchIndexRebuildUrl();
      await getDataFromCustomRoute(url, authToken, user);
      alert("Search Index rebuild started");
    })();
  };

  return (
    <Card className={styles.marginBottom} variant="outlined">
      <CardContent>
        <Typography variant="h4" className={styles.marginBottom}>
          Box Office
        </Typography>
        <Typography variant="body1" className={styles.marginBottom}>
          Do not trigger these actions unless you understand what they do. Check
          build progress at{" "}
          <a href={boxOfficeDeploymentsUrl()} target="_blank">
            {boxOfficeDeploymentsUrl()}
          </a>
        </Typography>
        <Button
          className={styles.marginRight}
          variant="contained"
          color="primary"
          onClick={rebuildBoxOffice}
        >
          Rebuild Box Office
        </Button>
        <Button
          className={styles.marginRight}
          variant="contained"
          color="primary"
          onClick={rebuildSearchIndex}
        >
          Rebuild Search Index
        </Button>
      </CardContent>
    </Card>
  );
};

export default DashboardPage;
