// @ts-nocheck
import simpleRestProvider from "ra-data-simple-rest";
import * as RA from "react-admin";
import { fetchAssetApiUrl } from "../../utils/urls";

const DataProvider = (
  authToken: string | null,
  userId: string | undefined,
  userEmail: string | undefined
): RA.DataProvider => {
  const user = {
    sub: userId,
    email: userEmail,
  };
  const httpClient = (url: string, options: any = {}) => {
    if (!options.headers) {
      options.headers = new Headers();
    }
    options.headers.set("Content-Type", "application/json");
    options.headers.set("Authorization", `Bearer ${authToken}`);
    options.headers.set("x-admin-request", "true");
    options.headers.set("x-admin-user-id", userId);
    options.headers.set("x-admin-user-email", userEmail);
    return RA.fetchUtils.fetchJson(url, options);
  };
  const defaultDataProvider: RA.DataProvider = simpleRestProvider(
    process.env.REACT_APP_DATA_PROVIDER_URI || "http://localhost:5000",
    httpClient
  );

  return {
    ...defaultDataProvider,
    // create: POST `/resource`
    create: async (resource: string, params: any) => {
      params.data = await handleFileData(params.data);
      const isListingResource = resource === "listings";
      const isOrdersResource = resource === "orders";
      const isAssetsResource = resource === "assets";

      if (isListingResource) {
        params = await handleListingsParams(params, authToken, user);
      }

      if (isOrdersResource) {
        params.data.listingId = parseInt(params.data.listingId);
      }

      if (isAssetsResource) {
        if (params.data.ignore) {
          params.data.xPoint = null;
          params.data.yPoint = null;
          params.hideLocation = false;
        }

        if (params.data.hideLocation) {
          params.data.xPoint = null;
          params.data.yPoint = null;
        }
      }

      return defaultDataProvider.create(resource, params).then((res) => {
        res.data = removeFormattedArrayProps(res.data);

        if (isListingResource) {
          res.data.seats = getMaxTicketsAvailable(res.data.ticketGroupSizes);
        }

        return res;
      });
    },
    // update: PUT `/resource`
    update: async (resource: string, params: any) => {
      params.data = await handleFileData(params.data);
      const isListingResource = resource === "listings";
      const isAssetsResource = resource === "assets";

      if (isListingResource) {
        params = await handleListingsParams(params, authToken, user);
      }

      if (isAssetsResource) {
        if (params.data.ignore) {
          params.data.xPoint = null;
          params.data.yPoint = null;
          params.hideLocation = false;
        }

        if (params.data.hideLocation) {
          params.data.xPoint = null;
          params.data.yPoint = null;
        }
      }

      return defaultDataProvider.update(resource, params).then((res) => {
        res.data = removeFormattedArrayProps(res.data);

        if (isListingResource) {
          res.data.seats = getMaxTicketsAvailable(res.data.ticketGroupSizes);
        }

        return res;
      });
    },
    // getList: GET /resource?sort=['title','ASC']&range=[0, 24]`
    getList: async (resource: string, params: any) => {
      const listingCreateUrl = "#/listings/create?source=";

      if (window.location.hash.includes(listingCreateUrl)) {
        const urlString = window.location.hash.replace(listingCreateUrl, "");
        const urlParams = JSON.parse(decodeURI(urlString));
        if (urlParams.assetId) {
          params = {
            ...params,
            filter: { ...params.filter, eventsByAssetId: urlParams.assetId },
          };
        }
        if (urlParams.eventId) {
          params = {
            ...params,
            filter: { ...params.filter, assetsByEventId: urlParams.eventId },
          };
        }
      }

      return defaultDataProvider.getList(resource, params);
    },
    // getOne: GET /resource/:id
    getOne: async (resource: string, params: any) => {
      const isListingResource = resource === "listings";

      if (isNaN(params.id)) {
        return Promise.reject();
      }

      return defaultDataProvider.getOne(resource, params).then((res) => {
        res.data = removeFormattedArrayProps(res.data);

        if (resource === "events" || resource === "performers") {
          res.data.performerIds = res.data.performers.map(
            (p: Record<any, any>) => p.id
          );
          delete res.data.performers;
        }
        if (resource === "markets") {
          res.data.venueIds = res.data.venues.map(
            (v: Record<any, any>) => v.id
          );
          delete res.data.performers;
        }
        if (isListingResource) {
          res.data.seats = getMaxTicketsAvailable(res.data.ticketGroupSizes);
        }

        return res;
      });
    },
    // getMany: GET /resourcefilter={id:[123,456,789]}
    getMany: async (resource: string, params: any) => {
      if (params.ids) {
        params.ids = params.ids.map((id: string) => parseInt(id));
      }

      return defaultDataProvider.getMany(resource, params).then((res) => {
        return res;
      });
    },
  };
};

export const getDataFromCustomRoute = async (url, authToken, user = {}) => {
  const data = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      "x-admin-request": true,
      "x-admin-user-id": user.sub,
      "x-admin-user-email": user.email,
    },
  });

  return data.json();
};

export const postDataToCustomRoute = async (
  url,
  body,
  authToken,
  user = undefined
) => {
  const data = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      "x-admin-request": true,
      "x-admin-user-id": user?.sub,
      "x-admin-user-email": user?.email,
    },
    body: JSON.stringify(body),
  });

  return data.json();
};

const handleFileData = async (data) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for await (const [key, value] of Object.entries(data)) {
    if (value?.rawFile && value?.rawFile instanceof File) {
      data.fileData = await handleFileProperties(value);
    }
    // Note: Not needed, could use testing if end up using
    // if (Array.isArray(value)) {
    //   data[key] = await Promise.all(
    //     value.map(async (obj) => {
    //       if (
    //         obj?.field &&
    //         obj?.field?.rawFile &&
    //         obj?.field?.rawFile instanceof File
    //       ) {
    //         return await convertFilesToBase64(obj.field);
    //       }
    //       return obj;
    //     })
    //   );
    // }
  }

  return data;
};

const handleFileProperties = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  }).then((result) => {
    return {
      base64: result,
      name: file.rawFile.name,
    };
  });
};

const handleListingsParams = async (params: any, authToken, user) => {
  const asset = await getDataFromCustomRoute(
    fetchAssetApiUrl(params.data.assetId),
    authToken,
    user
  );

  const ticketsIncluded = params.data.seats || asset.maxSeatsAvailable;
  const ticketGroupSizes =
    params.data.accessType === "private"
      ? [ticketsIncluded]
      : Array.from({ length: ticketsIncluded }, (_, i) => i + 1);
  delete params.data.seats;

  params.data = {
    ...params.data,
    listingSource: "suitehop_admin",
    ticketGroupSizes,
  };

  return params;
};

const removeFormattedArrayProps = (data: Record<any, any>) => {
  const includedKeys = ["performerIds", "venueIds"];

  Object.keys(data).forEach((key) => {
    if (includedKeys.includes(key) && data[key].length) {
      data[key] = data[key].map((i: { field: any }) => i.field);
    }
  });

  return data;
};

const getMaxTicketsAvailable = (ticketGroupSizes: { field: number }[]) => {
  const ticketGroups = ticketGroupSizes.map((tg) => tg.field);
  return Math.max(...ticketGroups);
};

export default DataProvider;
