// Note: These would be much better if we could somehow import these directly from our Prisma schema,
// otherwise we are going to have drift at some point

export const formatted = (arr: string[]): Record<string, string>[] =>
  arr.map((choice) => ({
    id: choice.replaceAll(" ", "_").replaceAll("/", "_"),
    name: choice,
  }));

export const VenueType = formatted([
  "amphitheater",
  "arena",
  "festival",
  "golf",
  "motorsports",
  "stadium",
  "tennis",
]);

export const PerformerType = formatted(["artist", "team", "big event"]);

export const TimeZone = formatted([
  "us/pacific",
  "us/arizona",
  "us/mountain",
  "us/central",
  "us/eastern",
]);

export const Category = formatted([
  "ahl",
  "alternative  indie",
  "alternative rock",
  "auto racing",
  "award shows",
  "ballet  dance",
  "baseball",
  "basketball",
  "beyonce",
  "boxing",
  "center stage",
  "center stage",
  "cfp national championship",
  "chris stapleton",
  "classical",
  "clippers home game",
  "comedy",
  "concerts",
  "country  folk",
  "danceelectronic",
  "default",
  "drake",
  "echl",
  "ed sheeran",
  "entertainment shows",
  "family",
  "federacion mexicana de futbol",
  "festivals",
  "fighting",
  "football",
  "formula 1",
  "golf",
  "hard rockmetal",
  "high school basketball",
  "hockey",
  "horse racing",
  "ifl",
  "indycar series",
  "jazz  blues",
  "justin timberlake",
  "lacrosse",
  "lakers home game",
  "latin",
  "lpga",
  "megan thee stallion",
  "metallica",
  "minor league baseball",
  "miscellaneous",
  "miscellaneous special events",
  "miscellaneous sports",
  "mixed martial arts mma",
  "mlb",
  "mls",
  "mls major league soccer",
  "monster trucks",
  "morgan wallen",
  "motorsports",
  "musicals",
  "nascar",
  "nba",
  "nba gleague",
  "ncaa",
  "ncaa baseball",
  "ncaa football",
  "ncaa mens basketball",
  "ncaa mens hockey",
  "ncaa womens basketball",
  "new age  spiritual",
  "nfl",
  "nhl",
  "nhra",
  "nll",
  "nwsl",
  "other events",
  "pga",
  "rap  hiphop",
  "rburban soul",
  "rock  pop",
  "rodeo",
  "rolling stones",
  "rugby",
  "sec football championship",
  "soccer",
  "special events",
  "sports",
  "superbowl",
  "supercross",
  "taylor swift 2023",
  "taylor swift 2024",
  "tennis",
  "theatre",
  "tv shows",
  "usher",
  "volleyball",
  "whl",
  "wnba",
  "world cup",
  "world music",
  "wrestling",
  "zach bryan",
]);

export const FeedParserGroup = formatted([
  "party",
  "party suite",
  "c level",
  "default",
]);

export const CateringType = formatted([
  "not catered",
  "all inclusive with alcohol",
  "premium buffet",
  "catering package",
]);

export const SeatingType = formatted([
  "suite",
  "vip seating",
  "vip club",
  "shared access",
  "vip box",
  "vip package",
  "loge box",
  "courtside seats",
  "glass seats",
  "front row",
  "vip tailgate",
]);

export const AssetSource = formatted(["suitehop admin", "ticket evolution"]);

export const ListingSource = formatted([
  "suitehop admin",
  "listing partner admin",
  "ticket evolution",
]);

export const AccessType = formatted(["private", "shared", "NA"]);

export const BookingMethod = formatted([
  "instant book",
  "confirm",
  "ticket evolution",
]);

export const SellerListingFrequency = formatted([
  "single event",
  "multiple events",
]);

export const SellerApplicationStatus = formatted([
  "pending",
  "approved",
  "denied",
]);

export const ListingStatus = formatted([
  "available",
  "pending",
  "sold",
  "sold elsewhere",
  "expired",
  "admin removed",
]);

export const Country = formatted(["usa", "canada"]);

export const OrderStatus = formatted([
  "pending",
  "approved",
  "rejected",
  "delivered",
  "cancelled",
  "refunded",
]);
